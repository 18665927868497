<script setup lang="ts">
const VITE_BUILD_COMMIT = import.meta.env.VITE_BUILD_COMMIT;
</script>

<template>
  <div fixed rounded-br-4 bottom-0 left-0 z-9999 px-4 py-2 bg-black text-white op-75>
    commit: {{ VITE_BUILD_COMMIT }}
  </div>
  <div>$__DEV__: {{ $__DEV__ }}</div>
  <RouterView />
</template>
